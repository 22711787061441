<template>
  <!-- Information Card 2 -->
  <a-card :bordered="false" class="card-info-2 h-full">
    <div
      class="col-content h-full"
      style="background-image: url('/images/info-card-2.jpg')"
    >
      <div class="card-content">
        <h5>Versement en point Launship-Box</h5>
        <p>
          Vous voulez développer votre activité de collecte dans tous les
          quartiers sans créer d'agences physiques? <br />
          C'est possible grâce au point de relais Launship Box disponible dans
          tous les quartiers de Lomé
        </p>
      </div>
    </div>
  </a-card>
  <!-- / Information Card 2 -->
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>